<template>
  <IonApp @loggedin="jscReload()">
    <ion-page id="main" v-if="shared.loading.value">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
          <ion-title>LOADING...</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content > <!--:fullscreen="true"> -->
        <ion-progress-bar></ion-progress-bar>
        <pre v-if="debug">{{shared}}</pre>
      </ion-content>
    </ion-page>
    <IonSplitPane content-id="main-content" v-else>
      <ion-menu content-id="main-content" type="overlay" class="jscMenu">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header>Inbox</ion-list-header>
            <ion-note>{{ user }}
                  </ion-note>


            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
<!--
          <ion-list id="labels-list">
            <ion-list-header>Labels</ion-list-header>

            <ion-item v-for="(label, index) in labels" lines="none" :key="index">
              <ion-icon slot="start" :ios="bookmarkOutline" :md="bookmarkSharp"></ion-icon>
              <ion-label>{{ label }}</ion-label>
            </ion-item>
          </ion-list>
          -->
        </ion-content>
      </ion-menu>


          <ion-router-outlet id="main-content" >
          </ion-router-outlet>
    </IonSplitPane>

  </IonApp>
</template>

<script >
import { defineComponent, ref, onErrorCaptured , watch } from 'vue';
import { useRoute } from 'vue-router';
import {  archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, cogOutline, cogSharp } from 'ionicons/icons';
import shared from "./shared/shared.js";
export default defineComponent({
  name: 'App',
  provide:{shared},
  setup() {
    shared.loadInit();
    const selectedIndex = ref(0);
    const loggedInPages = [
      {
        title: 'Les Menus',
        url: '/dynLister/menus',
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
      {
        title: 'Creer boutique',
        url: '/jsc/boutique/',
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
      {
        title: 'Liste boutiques',
        url: '/jsc/boutiques',
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
      {
        title: 'Contact',
        url: '/Contact',
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
      {
        title: 'Settings',
        url: '/settings',
        iosIcon: cogOutline,
        mdIcon: cogSharp
      },
/*      {
        title: 'Mail',
        url: '/mail',
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
      {
        title: 'Modules',
        url: '/mods',
        iosIcon: mailOutline,
        mdIcon: mailSharp
      },
      {
        title: 'Outbox',
        url: '/folder/Outbox',
        iosIcon: paperPlaneOutline,
        mdIcon: paperPlaneSharp
      },
      {
        title: 'Favorites',
        url: '/folder/Favorites',
        iosIcon: heartOutline,
        mdIcon: heartSharp
      },
      {
        title: 'Archived',
        url: '/folder/Archived',
        iosIcon: archiveOutline,
        mdIcon: archiveSharp
      },
      {
        title: 'Liste',
        url: '/liste',
        iosIcon: trashOutline,
        mdIcon: trashSharp
      },
      {
        title: 'New',
        url: '/new',
        iosIcon: warningOutline,
        mdIcon: warningSharp
      },
 */     {
        title: 'Login',
        url: '/auth/login',
        iosIcon: warningOutline,
        mdIcon: warningSharp
      }

    ];

    const appPages = ref([
      {
        title: 'Login',
        url: '/auth/login',
        iosIcon: warningOutline,
        mdIcon: warningSharp
      }
    ]);
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];


    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.value.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    watch(
        () => shared.user.value,
        (user, prevUser) => {
          //alert('user changed')
          appPages.value = loggedInPages;

          /* ... */
        }
    )

    const route = useRoute();

    return {
      user:shared.user,
      selectedIndex,

      appPages,
      labels,
      debug:shared.debug,

      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      global,
      shared,
      cogOutline,
      cogSharp,
  //    add,
      isSelected: (url) => url === route.path ? 'selected' : ''
    }
  }
});
</script>

<style scoped>

.login {
  --background: #fff url("/img/pexels-anand-dandekar-1532771.jpg") no-repeat center center / cover;
}

ion-menu ion-content {
  --background: #fff url("/img/pexels-cliford-mervil-2469122small.jpg") no-repeat center center / cover;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
