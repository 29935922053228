import {createApp} from 'vue'
//import App from './App'

import App from './App.vue'
import router from './router/index.js';

//Vue.config.productionTip = false
/*
new Vue({
  el: '#app',
  render: h => h(App)
})*/

import {
  IonApp, IonAvatar, IonBackButton,
  IonBadge, IonButton,
  IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent, IonDatetime, IonFab,
  IonFabButton, IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonicVue,
  IonImg, IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu, IonMenuButton,
  IonMenuToggle,
  IonModal,
  IonNote, IonPage, IonProgressBar, IonRouterOutlet, IonRow,
  IonSelect,
  IonSelectOption, IonSplitPane, IonTextarea, IonTitle, IonToolbar,
  IonSearchbar,
  IonText,
  IonToggle, IonThumbnail, IonSlide, IonSlides, IonSkeletonText
} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

// Above the createApp() line
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { addIcons } from 'ionicons';
import { add } from 'ionicons/icons';

addIcons({
  "ios-add": add.ios,
  "md-add": add.md

});


/*
const PouchDB = require('pouchdb').default;
PouchDB.plugin(require('pouchdb-find'));
import findPlugin from "pouchdb-find";
PouchDB.plugin(findPlugin);

const dbname="mabase"
const dburl='https://cdc6c022-5f80-429e-bcf4-3dbd5da54da3-bluemix:49a777db66d1f95b746e589d469b842eb6eb6ed8a9afb5a6b10c042c4573bdf4@cdc6c022-5f80-429e-bcf4-3dbd5da54da3-bluemix.cloudantnosqldb.appdomain.cloud/';

const localdb = new PouchDB(dburl+dbname);
*/


//import shared from "./shared/shared.js";

import cuid from 'cuid';

import './registerServiceWorker'



const app = createApp(App)
 .use(IonicVue)
  .use(router)
.use(cuid);
//  .use(localdb);

//app.provide(shared,"shared")

//import  {Ml}  from '@/views/Ml.vue';

//app.component('Ml',Ml);


app.component('IonApp',IonApp);
app.component('ion-avatar',IonAvatar);
app.component('ion-button',IonButton);
app.component('ion-list-header',IonListHeader);
app.component('ion-content',IonContent);
app.component('ion-datetime',IonDatetime);
app.component('ion-icon',IonIcon);
app.component('ion-input',IonInput);
app.component('ion-item',IonItem);
app.component('ion-label',IonLabel);
app.component('ion-list',IonList);
app.component('ion-note',IonNote);
app.component('ion-menu-toggle',IonMenuToggle);
app.component('ion-menu',IonMenu);
app.component('ion-router-outlet',IonRouterOutlet);
app.component('IonSplitPane',IonSplitPane);
app.component('ion-header',IonHeader);
app.component('ion-img',IonImg);
app.component('ion-col',IonCol);
app.component('ion-row',IonRow);
app.component('ion-grid',IonGrid);
app.component('ion-checkbox',IonCheckbox);
app.component('ion-buttons',IonButtons);
app.component('ion-badge',IonBadge);
app.component('ion-textarea',IonTextarea);
app.component('ion-toolbar',IonToolbar);
app.component('ion-title',IonTitle);
app.component('ion-menu-button',IonMenuButton);
app.component('ion-footer',IonFooter);
app.component('ion-page',IonPage);
app.component('ion-fab',IonFab);
app.component('ion-fab-button',IonFabButton);
app.component('ion-select',IonSelect);
app.component('ion-select-option',IonSelectOption);
app.component('ion-modal',IonModal);

app.component('ion-card',IonCard);
app.component('ion-card-content',IonCardContent);
app.component('ion-card-title',IonCardTitle);
app.component('ion-card-subtitle',IonCardSubtitle);
app.component('ion-card-header',IonCardHeader);

app.component('ion-progress-bar',IonProgressBar);
app.component('ion-back-button',IonBackButton);

app.component('ion-searchbar',IonSearchbar);

app.component('ion-text',IonText);
app.component('ion-toggle',IonToggle);
app.component('ion-thumbnail', IonThumbnail);

app.component('ion-slide', IonSlide);

app.component('ion-slides', IonSlides);

//ion-skeleton-text
app.component('ion-skeleton-text', IonSkeletonText);

router.isReady().then(() => {
  app.mount('#app');
});

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
