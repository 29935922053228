import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import NewItem from '../views/NewItem.vue';


const routes = [
  {
    path: '/dynLister/:categorie',
    component: () => import ('../views/dynamique/DynLister.vue')
  },
  {
    path: '/settings',
    component: () => import ('../views/settings/Settings.vue')
  },
  {
    path: '/walk',
    component: () => import ('../views/walk/Walk.vue')
  },
  {
    path: '/contact',
    component: () => import ('../views/Contact.vue')
  },
  {
    path: '/auth/:action',
    component: () => import ('../views/Signin.vue')
  },
  {
    path: '/mail',
    component: () => import ('../views/Mail.vue')
  },
  {
    path: '/jsc/boutiques',
    component: () => import ('../views/BoutiqueLister.vue')
  },
  {
    path: '/jsc/:type/:id/rdv',
    component: () => import ('../views/BoutiqueRdv.vue')
  },
  {
    path: '/jsc/:type/:id',
    component: () => import ('../views/BoutiqueVoir.vue')
  },
  {
    path: '/jsc/:type',
    component: () => import ('../views/BoutiqueCreer.vue')
  },
  {
    path: '/mods/:type/:id',
    component: () => import ('../views/BaseVoir.vue')
  },
    {
    path: '/mods/:type',
    component: () => import ('../views/BaseLister.vue')
  },
    {
    path: '/mods',
    component: () => import ('../views/BaseMods.vue')
  },
    {
    path: '/new',
    component: () => import ('../views/NewItem.vue')
  },
  {
    path: '/',
    redirect: '/dynLister/menus'
  },
  {
    path: '/toto',
    redirect: '/folder/Inbox'
  },
  {
    path: '/folder/:id',
    component: () => import ('../views/Folder.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
