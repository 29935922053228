<template>
      <ion-modal
          :is-open="isOpen"
          css-class="my-custom-class"
          @onDidDismiss="handleDidDismiss(true)"
      >
        <ion-page>
        <ion-header translucent>
          <ion-toolbar>
            <ion-title>Modal Content</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="handleDidDismiss(false)">Save</ion-button>
              <ion-button color="danger" @click="handleDidDismiss(true)">Cancel</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>
        <ion-content fullscreen class="ion-padding">
<ion-item>
  <ion-label>Titre</ion-label>
  <ion-input v-model="formInfo.titre"></ion-input>
</ion-item>
          <ion-item>
            <ion-label>Sous-Titre</ion-label>
            <ion-textarea v-model="formInfo.soustitre" rows="4"></ion-textarea>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Date</ion-label>
            <ion-datetime display-format="MM DD YY" placeholder="Select a date" v-model="formInfo.date"></ion-datetime>
          </ion-item>
<ion-item>
  <ion-button @click="handleDidDismiss(false)">Save</ion-button>

</ion-item>
          <!--          <ion-list>
                      <ion-item>
                        <ion-avatar slot="start">
                          <ion-img src="./avatar-gollum.jpg"/>
                        </ion-avatar>
                        <ion-label>
                          <h2>Gollum</h2>
                          <p>Sneaky little hobbitses!</p>
                        </ion-label>
                      </ion-item>
                      <ion-item>
                        <ion-avatar slot="start">
                          <ion-img src="./avatar-frodo.jpg"/>
                        </ion-avatar>
                        <ion-label>
                          <h2>Frodo</h2>
                          <p>Go back, Sam! I'm going to Mordor alone!</p>
                        </ion-label>
                      </ion-item>
                      <ion-item>
                        <ion-avatar slot="start">
                          <ion-img src="./avatar-samwise.jpg"/>
                        </ion-avatar>
                        <ion-label>
                          <h2>Samwise</h2>
                          <p>What we need is a few good taters.</p>
                        </ion-label>
                      </ion-item>
                    </ion-list> -->
        </ion-content>
        </ion-page>
      </ion-modal>
</template>

<script>
import {ref, computed, watch, reactive} from 'vue'
export default {
  name: "BaseForm",
  props:{
  isOpen :{
    default:false,
  },
    initialValues :{
      default: {},
    }

  },
  setup(props, context){
    const formInfo=reactive({
      titre:"mon titre",
      soustitre:"",
      date:""
    });const inEditMode = computed(()=> props.initialValues? props.initialValues.id?true:false:false)

  const handleDidDismiss= (isCancelled) => {


    if (!props.isOpen) return;
   /* alert(JSON.stringify(formdata.value))
    alert(JSON.stringify(props.initialValues))
    alert(JSON.stringify(props))
    alert(JSON.stringify(inEditMode))
    alert(JSON.stringify(inEditMode.value))*/
//alert(JSON.stringify(formdata.value))
  //  alert(JSON.stringify(formdata))
    //alert(JSON.stringify(props.initialValues))

 let payload = null;
 if (isCancelled){
   payload = {
     isCancelled: true,
     formInfo: null
   };

 } else if (inEditMode.value){
   payload = {
     isCancelled: false,
     formInfo: {...formInfo, id:props.initialValues? props.initialValues.id? props.initialValues.id:null:null}
   };

 } else {
//      context.emit("modal-closed", {isCancelled, forminfo: !isCancelled?  formdata.value:null});
   delete formInfo.id;
   payload = {
     isCancelled: false,
     formInfo: {...formInfo}
   };
 }


 context.emit("modal-closed", payload);
    Object.assign(formInfo, {
      titre:"mon titre000",
      soustitre:"",
      date:""
    })

};
 watch(
     ()=> props.initialValues,
     (newVal) => {
       //alert('changement')
       //alert(JSON.stringify(newVal))
       //alert(inEditMode.value)
       if (newVal && inEditMode.value) {
         Object.assign(formInfo, props.initialValues)
         //alert(JSON.stringify(formInfo))
       }
     }
 )
return {
 //computed
 inEditMode,
 //function
     handleDidDismiss,
  formInfo



}
}
}
</script>

<style scoped>

</style>
