
<template>
<ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>Add Item</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
       <baseForm
            :is-open="modalInfo.show"
            :initialValues="modalInfo.data"
            css-class="my-custom-class"
            @modal-closed="handleModalClosed"
        >
        </baseForm>
      <ion-list>
        <ion-item v-for="obj in listData" :key="obj.id">
          <ion-label @click="handleEdit(obj)">
            <div>{{ obj.id }}</div>
            <div class="ion-text-wrap"> {{ obj.titre }}</div>
          </ion-label>
          <ion-label>
            <div class="ion-text-wrap"> {{ obj.date }}</div>
          </ion-label>
          <ion-button @click.self="handleDelete(obj)" slot="end" fill="clear">
            <ion-icon :icon="trashSharp" slot="icon-only" color="danger"></ion-icon></ion-button>
        </ion-item>

      </ion-list>
      <pre>{{JSON.stringify(listData,null,2)}}</pre>
    <h1>toto</h1>
      <ion-item>
        <ion-label>Modele</ion-label>
        <ion-select placeholder="Select One" @ionChange="getmodele($event.target.value)" >
          <ion-select-option v-for="mod in lesmodeles" :key="mod._id" :value="mod._id" >{{ mod.nom }}</ion-select-option>

        </ion-select>
      </ion-item>

      <ion-list v-if="jsccrud !== null">
        <ion-item v-for="obj in jsccrud.objs" :key="obj._id">
          <ion-label>{{ obj._id }}</ion-label>
          <ion-label>{{ obj.nom }}</ion-label>
        </ion-item>
      </ion-list>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="setOpen()">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>


    </ion-content>
  </ion-page>
</template>
<script>
const PouchDB = require('pouchdb').default;
PouchDB.plugin(require('pouchdb-find'));
import findPlugin from "pouchdb-find";
PouchDB.plugin(findPlugin);
import axios from 'axios';
import { add, trashSharp } from 'ionicons/icons';
import {defineComponent} from 'vue';

import BaseForm from "./BaseForm";

export default ({
  name: 'NewItem',
  components:{
    BaseForm
  },
  data(){
    return {
      dbname:"mabase",
      dburl:'https://cdc6c022-5f80-429e-bcf4-3dbd5da54da3-bluemix:49a777db66d1f95b746e589d469b842eb6eb6ed8a9afb5a6b10c042c4573bdf4@cdc6c022-5f80-429e-bcf4-3dbd5da54da3-bluemix.cloudantnosqldb.appdomain.cloud/',
      localdb:null,
      lesmodeles:null,
      jsccrud:null,
      isOpenRef : false,
      data : { content: 'New Content' },
      add,
      trashSharp,
      modalInfo:{
        show:false,
        data:null
      },
      listData: {}

    }
},
  created(){
    const user="cdc6c022-5f80-429e-bcf4-3dbd5da54da3-bluemix"
    const pwd="49a777db66d1f95b746e589d469b842eb6eb6ed8a9afb5a6b10c042c4573bdf4"
    this.token = Buffer.from(`${user}:${pwd}`, 'utf8').toString('base64')
    axios({
      method:'get',
      url: this.dburl + this.dbname,
      headers:{
        'Content-type': 'application/json',
        'Authorization': `Basic ${this.token}`
      }
    }).then((m) => {
      this.localdb = new PouchDB(this.dburl+this.dbname);
      this.loadobjs()
    }).catch((e) => {
      console.log(e);
      alert("je dois la creer");
      axios({
        method:'put',
        url: this.dburl + this.dbname + "?partitioned=true",
        headers:{
          'Content-type': 'application/json',
          'Authorization': `Basic ${this.token}`
        }
      }).then((m) => {
        alert("base cree");
        this.localdb = new PouchDB(this.dburl+this.dbname);
        this.loadobjs()
      }) .catch((e) => {})
    });
  },
  methods:{
    loadobjs(){
      //alert('loadobj')
      console.log(this.localdb)
      this.localdb.find({
        selector: {
          type: 'modele'
        }
      })
          .then((result) => {
            //alert("recupere")
            // var a= this.dyna;
            //this.dynadb={...a, ...doc};
            // console.log(this.dynadb);
            console.log(result.docs)
            this.lesmodeles=result.docs
            // this.jsccrud=doc;
          })
          .catch((err) => {
            alert(err);
          });
    },
    getmodele(val){
      if (!val)
        return
      alert(val)
      console.log(val)
      this.localdb.get(val)
          .then((doc) => {
            alert("recupere")
//var a= this.dyna;
            //  this.dynadb={...a, ...doc};
            //console.log(this.dynadb);
            console.log(doc);
            console.log(this.jsccrud)
            /*const keys1 = Object.keys(doc);
            const keys2 = Object.keys(this.jsccrud);

            if (keys1.length !== keys2.length) {
              console.log("pas meme longueur de cles doc "+keys1.length+ " mais jsccrud "+keys2.length)
            }

            for (let key of keys1) {
              if (doc[key] !== this.jsccrud[key]) {
                console.log("difference " +key)
                console.log(doc[key])
                console.log(this.jsccrud[key])
              }
            }*/
            /*
                          for (let key of keys2) {
                            if (doc[key] !== this.jsccrud[key]) {
                              console.log("difference " +key)
                              console.log(doc[key])
                              console.log(this.jsccrud[key])
                            }
                          }*/

            this.jsccrud=doc;
            //j ai mis la structure mais maitenant je dois charger les objets correspondants
            this.localdb.find({
              selector: {
                type: {
                  $eq: this.jsccrud.typeobj,
                }
              }
            })
                .then((result) =>{
                  alert("ici");
                  console.log(result.docs)
                  this.jsccrud.objs=result.docs
                  console.log('la')
                }).catch((err)=> {
              alert(err);
              console.log(err);
            });




          })
          .catch((err) => {
            alert(err);
          });



    },
    handleModalClosed(event){
      //alert(JSON.stringify(event))
      this.modalInfo.show = false;
      if (event.isCancelled) {
        alert("Cancelled")
      } else {
        if (event.formInfo.id ){
                    this.listData[event.formInfo.id] = {
            ...event.formInfo
          }
        }
        else {
          //want to save the data
          const id = new Date().getTime().toString();
          this.listData[id] = {
            id,
            ...event.formInfo
          }
        }
      }
      alert('ja vais fermer')
      this.modalInfo.data=null
      this.modalInfo.show = false;
      alert('ferme')
    },
    handleDelete(item){
//      this.listData.delete(item.id)
      delete this.listData[item.id]
    },
    handleEdit(item){
//      this.listData.delete(item.id)
      console.log(item)

      this.modalInfo.show = true;
      this.modalInfo.data={...item};

    },
    setOpen () {
      this.modalInfo.show = true;
    }
  },
/*
  setup(){
   /* const isOpenRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
    const data = { content: 'New Content' };
    return { add}

  },*/
});
</script>
