import { reactive, readonly, ref , toRefs, toRef, unref} from "vue";

import { Plugins, CameraResultType, CameraSource, CameraPhoto,
Capacitor, FilesystemDirectory } from "@capacitor/core";

import { isPlatform } from '@ionic/vue';

const { Camera } = Plugins;


let debug = ref(false);
const PouchDB = require('pouchdb').default;
import findPlugin from "pouchdb-find";
PouchDB.plugin(findPlugin);

const dbname="mabase"
const dburl='https://cdc6c022-5f80-429e-bcf4-3dbd5da54da3-bluemix:49a777db66d1f95b746e589d469b842eb6eb6ed8a9afb5a6b10c042c4573bdf4@cdc6c022-5f80-429e-bcf4-3dbd5da54da3-bluemix.cloudantnosqldb.appdomain.cloud/';
//const dburl='http://127.0.0.1:8000/';
const ldb = new PouchDB(dburl+dbname)
const localdb = reactive(ldb);
const type = ref("modele");
var jscmodeles = reactive({});
var lemodele = reactive({});
var laListe = reactive({});
var selectedId = ref(null);
var selectedItem = reactive({});

//ajouter
//le default
//le edit

const error = ref("");
const loading = ref(false);


const state = reactive({
  count: 0
});
const increment = function () {
  state.count++;
  jscFind();
}




const loadInit = async ()=>{
    loading.value = true;
    error.value = null;
    try{
        let result = await localdb.find({
            selector: {
                type: 'modele'
            }
        });
        jscmodeles.value=result.docs
        laListe.value=result.docs
        lemodele.value = result.docs.filter(res => res.typeobj=='modele')[0];
    } catch (err){
        error.value=err;
    } finally {
        loading.value = false;
    }
}

const lister = (typeObjet) =>{
    //alert(JSON.stringify(mod))
    //alert(mod.typeobj);
    //si le type est modele mais la autre chose -> je change le type et reload donc
    if (typeObjet!==type.value){
        //alert(JSON.stringify(typeObjet))
        type.value=typeObjet;
        lemodele.value=jscmodeles.value.filter(res => res.typeobj==typeObjet)[0]; // JE DOIS FAIRE FILTRE SUR typeobj
        //alert(JSON.stringify(lemodele.value))
    }
    let filter = {
        selector: {
            type: type.value
        }
    }
    find(filter);
};

const findByType = async (objtype) => {
    let filter = {
        selector: {
            type: objtype
        }
    }
    return localdb.find(filter);
}

const jscFind = async ()=>{
    //alert(JSON.stringify(jscmodeles))
    await loadInit();
    /*
    *       .catch((err) => {
        alert(err);
      });
*/
    //alert(JSON.stringify(jscmodeles))
}

const find = (sel)=>{
    //alert("ici")
    localdb.find(sel)
        .then((result) => {
            //alert("la")
            laListe.value=result.docs
        })
        .catch((err) => {
            alert(err);
        });
}

const create = async (doc) => {

  return localdb.put(doc)
}

const user=ref(null)
 const takePhot = async () => {
    //alert('la')

    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      quality: 5,
      width: 50,
      height: 50,
      preserveAspectRatio: true
    });
return "data:image/jpeg;base64,"+photo.base64String;
 };


export default {findByType,user, selectedId, selectedItem, debug:debug,  state: readonly(state), increment, jscmodeles: jscmodeles, lemodele: lemodele , find, jscFind, loadInit,error, loading, laListe,lister, type, takePhot, create, localdb};
